exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-libros-tsx": () => import("./../../../src/pages/libros.tsx" /* webpackChunkName: "component---src-pages-libros-tsx" */),
  "component---src-templates-book-tsx": () => import("./../../../src/templates/Book.tsx" /* webpackChunkName: "component---src-templates-book-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

